import React from 'react';
import './Tekken8FrameDataCharacterSelect.css'

class Tekken8FrameDataCharacterSelect extends React.Component {
    render() {

        return (
            <div>
                <div class="row">
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/alisa.webp" onClick={() => this.fetch_moves()}></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/asuka.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/azucena.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/bryan.webp"></img>
                    </div>
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/claudio.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/deviljin.png"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/dragunov.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/eli.webp"></img>
                    </div>
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/feng.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/hwoarang.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/jack.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/jin.webp"></img>
                    </div>
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/jun.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/kazuya.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/king.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/kuma.webp"></img>
                    </div>
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/lars.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/law.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/lee.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/leroy.webp"></img>
                    </div>
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/lili.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/nina.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/paul.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/raven.webp"></img>
                    </div>
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8\char-portraits/reina.png"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/steve.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/victor.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/xiayou.webp"></img>
                    </div>
                    <div class="column">
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/yoshimitsu.webp"></img>
                        <img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/zafina.webp"></img>
                    </div>
                </div>
            </div>
        );
    }
}
export default Tekken8FrameDataCharacterSelect;