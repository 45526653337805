import React from 'react';
import './Tekken8FrameDataMoveList.css'
import './Tekken8FrameDataCharacterSelect.css'

let jsonData = [
	{ move_name: "Radiant Cut", character_name: 'victor', img_src: 'radiant-cut' },
  ];

class Tekken8FrameDataMoveList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		  clicked: false,
		  character: 'alisa',
		  game: 'tekken8'
		};
	}
	fetch_moves = (character, game) => {
		fetch('/api/framedata?character='+character+'&game=tekken8').then(res => res.json()).then(data => {
			jsonData = data[character];
			this.setState({
				clicked: true,
				character,
				game
			  });
		});
	};

  render() {
	const { clicked, character, game } = this.state;
    const moveList = jsonData.map((object, move_name,counterhit, damage, hitlevel, input, notes, onblock, onhit, img_src) => (
		<div class="movecontainer">
			<div class="movetitlebar">
				{object.move_name}
			</div>
			<div class="movepropertiescontaier">
				<div class="hitbox">
				  <a class="hitboximg" ><img alt="Hitbox Image"  src={"\/tekken8\/framedata\/"+ this.state.character + "\/" + object.img_src}></img></a>
				</div>
				<div class="movedatacontainer">
					<div class="moveinput">
					{object.input}
					</div>
					<div class="startup">
					{object.startup}
					</div>
					<div class="basedamage">
					{object.damage}
					</div>
					<div class="attacktype">
					{object.hitlevel}
					</div>		
					<div class="notes">
					{object.notes}
					</div>
					<div class="onhit">
					{object.onhit}
					</div>
					<div class="onblock">
					{object.onblock}
					</div>
					<div class="counterhit">
					{object.counterhit}
					</div>
				</div>
			</div>
		</div>
    ));

    return (
		<div>
			<div class="row">
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/alisa.webp" onClick={() => this.fetch_moves("alisa", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/asuka.webp"  onClick={() => this.fetch_moves("asuka", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/azucena.webp"  onClick={() => this.fetch_moves("azucena", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/bryan.webp"  onClick={() => this.fetch_moves("bryan", "tekken8")}></img>
				</div>
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/claudio.webp"  onClick={() => this.fetch_moves("claudio", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/deviljin.png"  onClick={() => this.fetch_moves("devil-jin", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/dragunov.webp"  onClick={() => this.fetch_moves("dragunov", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/eli.webp"  onClick={() => this.fetch_moves("eli", "tekken8")}></img>
				</div>
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/feng.webp"  onClick={() => this.fetch_moves("feng", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/hwoarang.webp"  onClick={() => this.fetch_moves("hwoarang", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/jack.webp"  onClick={() => this.fetch_moves("jack-8", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/jin.webp"  onClick={() => this.fetch_moves("jin", "tekken8")}></img>
				</div>
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/jun.webp"  onClick={() => this.fetch_moves("jun", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/kazuya.webp"  onClick={() => this.fetch_moves("kazuya", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/king.webp"  onClick={() => this.fetch_moves("king", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/kuma.webp"  onClick={() => this.fetch_moves("kuma", "tekken8")}></img>
				</div>
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/lars.webp"  onClick={() => this.fetch_moves("lars", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/law.webp"  onClick={() => this.fetch_moves("law", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/lee.webp"  onClick={() => this.fetch_moves("lee", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/leroy.webp"  onClick={() => this.fetch_moves("leroy", "tekken8")}></img>
				</div>
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/lili.webp"  onClick={() => this.fetch_moves("lili", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/nina.webp"  onClick={() => this.fetch_moves("nina", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/paul.webp"  onClick={() => this.fetch_moves("paul", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/raven.webp"  onClick={() => this.fetch_moves("raven", "tekken8")}></img>
				</div>
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8\char-portraits/reina.png"  onClick={() => this.fetch_moves("reina", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/steve.webp"  onClick={() => this.fetch_moves("steve", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/victor.webp"  onClick={() => this.fetch_moves("victor", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/xiayou.webp"  onClick={() => this.fetch_moves("xiaoyu", "tekken8")}></img>
				</div>
				<div class="column">
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/yoshimitsu.webp"  onClick={() => this.fetch_moves("yoshimitsu", "tekken8")}></img>
					<img class="portrait" alt="Character Portrait" src="/tekken8/char-portraits/zafina.webp"  onClick={() => this.fetch_moves("zafina", "tekken8")}></img>
				</div>
			</div>

		
		{clicked && (
			<div class="moves">


				{moveList}
			</div>
		)}
	</div>
		

    );}
}

export default Tekken8FrameDataMoveList;